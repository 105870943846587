import React from 'react'
import {GatsbyImage} from "gatsby-plugin-image";

export default function ServiceItem(props) {
    const item = props.item;
    const imageForData = props.imageForData;

    return (
        <div key={item.node.jsonId} id={`service_${item.node.jsonId}`} className={'basis-full sm:basis-1/2 section-item'}>
            <GatsbyImage image={imageForData.node.childImageSharp.gatsbyImageData} />
            <div className="text-wrapper">
                <h3 className='h3 title'>{item.node.title}</h3>
                <p className='decription'>{item.node.description}</p>
            </div>
        </div>
    )
}
