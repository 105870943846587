import React from "react"
import Layout from "../components/layout";
import HeroSection from "../components/sections/hero-section";
import ChartaSection from "../components/sections/charta-section";
import BlockchainsSection from "../components/sections/blockchains-section";
import ServicesSection from "../components/sections/services-section";
import SetupSection from "../components/sections/setup-section";
import ArchitectureSection from "../components/sections/architetcure";
import {Helmet} from "react-helmet";
import ContactSection from "../components/sections/contact-section";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>SG-1.online</title>
      </Helmet>
      <main>
        <HeroSection/>
        <ChartaSection/>
        {/* <BlockchainsSection/> */}
        <ServicesSection/>
        <SetupSection/>
        <ArchitectureSection/>
        <ContactSection/>
      </main>
    </Layout>
  )
}

export default IndexPage;
