import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function HeroSection(props) {
	return (
		<section className='grid hero-section'>
			<div className='hero-section-bg'>
				<StaticImage
					className='w-full hero-section-image'
					imgClassName='object-top'
					src='../../images/sections/hero-section/sg-1-hero-image.png'
					loading='eager'
					quality='100'
					sizes={"(max-width: 1200px), 2200px"}
					placeholder='dominantColor'
				/>
			</div>
			<div className='container relative h-full'>
				<div className={"flex h-full md:items-center items-end"}>
					<div className={"basis-auto"}>
						<h1 className='h1 max-w-[530px]'>Interchain Validator</h1>

						<p className='lead-text mt-[25px] md:max-w-[370px]'>
							SG-1 offers cutting edge validator architecture for the Interchain Era. Stake your assets with public SG-1 nodes. <br />
							Non-custodial. Secure. Innovative.
						</p>
						<div className='title-backdrop' />
					</div>
				</div>
			</div>
		</section>
	);
}
