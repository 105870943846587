import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ChartaItem from "../elements/charta-item";

export default function ChartaSection() {
	const graph = useStaticQuery(graphql`
		query {
			allDataContent: allDataChartaSectionJson {
				edges {
					node {
						image {
							alt
							src
						}
						title
						description
						jsonId
					}
				}
			}
			allImageContent: allFile(filter: { sourceInstanceName: { eq: "charta-section" } }) {
				edges {
					node {
						childImageSharp {
							fluid {
								originalName
							}
							gatsbyImageData(height: 66, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
						}
					}
				}
			}
		}
	`);

	const { allDataContent, allImageContent } = graph;

	return (
		<section className='charta-section' id={"charta"}>
			<div className='container'>
				<div className='flex flex-wrap items-start justify-between'>
					<div className={"basis-full md:basis-3/12"}>
						<h2 className='h2'>Charta</h2>
					</div>
					<div className={"basis-full md:basis-9/12 md:pl-10"}>
						<div className='charta-wrapper'>
							<div className='grid m-[-2px] grid-cols-1 md:grid-cols-2 gap-x-28 gap-y-16'>
								{allDataContent.edges.map((item) => {
									const imageForData = allImageContent.edges.find(
										(element) => element.node.childImageSharp.fluid.originalName === item.node.image.src
									);
									return <ChartaItem imageForData={imageForData} item={item} />;
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}