import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import ServiceItem from "../elements/service-item";

export default function ServicesSection() {

    const graph = useStaticQuery(graphql`
        query {
            allDataContent: allDataServicesSectionJson {
                edges {
                    node {
                        description
                        id
                        image {
                            alt
                            src
                        }
                        jsonId
                        title
                    }
                }
            }
            allImageContent: allFile(filter: {sourceInstanceName: {eq: "services-section" }}) {
                edges {
                    node {
                        childImageSharp {
                            fluid {
                                originalName
                            }
                            gatsbyImageData(
                                placeholder: NONE
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
        }
    `);

    const { allDataContent, allImageContent } = graph
    return (
        <section className='services-section'>
            <div className='container'>
                <div className='flex flex-wrap items-center justify-between title-wrapper'>
                    <div className={'basis-full sm:basis-1/2'}>
                        <h2 className="h2 title">Services</h2>
                    </div>
                    <div className={'basis-full sm:basis-1/2 transform-item'}>
                        <p className='services-lead gradient'>SG-1 Validator offers a variety of services to our delegator base.</p>
                    </div>
                </div>
                <div className="section-wrapper">
                    <div className='flex flex-wrap items-center services-grid'>

                    {allDataContent.edges.map(item => {
							const imageForData = allImageContent.edges.find(
								element =>
									element.node.childImageSharp.fluid.originalName === item.node.image.src
							)
							return (
								<ServiceItem imageForData={imageForData} item={item}/>
							)
						})}
                    </div>
                </div>
            </div>
        </section>
    )
}
