import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function ChartaItem(props) {
	const item = props.item;
	const imageForData = props.imageForData;

	return (
		<div className='charta-item' key={item.node.jsonId} id={`charta${item.node.jsonId}`}>
			<div className='flex items-end h-[66px]'>
				<GatsbyImage className='image' image={imageForData.node.childImageSharp.gatsbyImageData} />
			</div>
			<div className='mt-7'>
				<h3 className='h3'>{item.node.title}</h3>
				<p className='text'>{item.node.description}</p>
			</div>
		</div>
	);
}
