import React from "react";
import { Button } from "../elements/button";
import { StaticImage } from "gatsby-plugin-image";
import {Link} from "gatsby";

export default function ArchitectureSection() {
	return (
		<section className='architecture-section' id={'architecture'}>
			<div className='bg-boundary'>
				<div className='container'>
					<div className='flex flex-wrap justify-between'>
						<div className={"basis-full md:basis-1/2"}>
							<div className='content-wrapper '>
								<h2 className='h2 title'>SG-1 Validator Architecture</h2>
								<p className='description'>
									On the architecture of the server setup, the sentry nodes are the first front. Those are connected to other nodes
									in the Cosmos ecosystem and gossip about newest transactions, blocks and updates. The IP of the sentry is public.
								</p>
								<Link to={'/architecture'}>
									<Button class='secondary' label='Get to know more...' />
								</Link>
							</div>
						</div>
						<div className='basis-full md:basis-1/2 image-wrapper'>
							<StaticImage
								className='background-image'
								placeholder='none'
								src='../../images/sections/architecture-section/1-drone-image.png'
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
