import React from "react";
import { Button } from "../elements/button";
import openIcon from "../../images/sections/setup-section/1-open.svg";
import openSentry from "../../images/sections/setup-section/2-sentry.svg";
import openRelayer from "../../images/sections/setup-section/3-relayer.svg";
import openValidator from "../../images/sections/setup-section/4-validator.svg";
import openBackup from "../../images/sections/setup-section/5-backup.svg";
import { StaticImage } from "gatsby-plugin-image";
import SetupItem from "../elements/setup-item";
import {Link} from "gatsby";

export default function SetupSection() {
	return (
		<section className='setup-section' id={'validator'}>
			<div className='container bg-boundary'>
				<StaticImage className='background-image' placeholder='none' src='../../images/sections/setup-section/6-background-image.png' />
				<div className={"max-w-[480px]"}>
					<h2 className='h2 title'>SG-1 Validator Set-up</h2>
					<p className='description'>
						As the validator setup we have chosen to use a 5-tier system of nodes, deployed over a range of servers.
					</p>
					<Link to={'/validator'}>
						<Button class='secondary' label='Learn More' />
					</Link>
				</div>
				<div className='flex flex-wrap box-grid'>
					<h3 className='h3 subtitle gradient'>We deploy:</h3>
					<div className='mt-24 basis-full'>
						<div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2.5'>
							<SetupItem text='Open RPC/API Nodes' icon={openIcon} />
							<SetupItem text='Sentry Nodes' icon={openSentry} />
							<SetupItem text='Relayer Nodes (experimental)' icon={openRelayer} />
							<SetupItem text='Validator Nodes' icon={openValidator} />
							<SetupItem text='Backup Nodes' icon={openBackup} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
